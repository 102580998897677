<template>
    <div>
        <AccountSection title="Subscription" :center="true" :padded="true" v-if="isSubscribed">
          <div v-if="migrationPreviewLine">
            <h3>Preview changes to your subscription</h3>
            <p>
              New plan: {{newPlan.name}}
            </p>
            <p>
              New price: {{formatMoney(newPlan.amount / 100)}} / {{newPlan.interval}}
            </p>
            <p>
              Due today: {{formatMoney(migrationPreviewLine.amount / 100)}}
            </p>
            <Button class="special uppercase" icon="check" v-if="migrationPreviewLine" @click="migrate">
              Confirm {{migrationPreviewLine.amount? 'Upgrade & Charge' : 'Changes'}}
            </Button>
          </div>
        </AccountSection>
    </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import AccountSection from '../molecules/AccountSection.vue';
  import AccountSubscriptionMixin from '../mixins/AccountSubscriptionMixin.vue';
  import Button from '../atoms/Button.vue';

  export default Vue.extend({
    components: {
      AccountSection,
      Button,
    },
    mixins: [AccountSubscriptionMixin],
    data(){
      return {
        migrationPreview: null
      }
    },
    computed:{
      migrationPreviewLine(){
        try{
          console.log(this.migrationPreview.data.lines.data[0]);
          return this.migrationPreview.data.lines.data[0]
        } catch(e){
          return null
        }
      },
      newPlan(){
        return this.migrationPreviewLine && this.migrationPreviewLine.plan
      }
    },
    created(){
      this.$store.dispatch('subscriptions/load')
      this.loadMigrationPreview()
    },
    methods: {
      async loadMigrationPreview(){
        this.migrationPreview = await this.$store.dispatch('subscriptions/previewMigrate', this.$route.params)
      },
      async migrate(){
        console.log('migrating');
        await this.$store.dispatch('subscriptions/migrate', this.$route.params)
        console.log('migrated');
        this.$router.push({name: 'AccountSubscriptionView', query:{ deeplink: 'refresh-license'}})
      }
    }
  });
</script>
<style scoped lang="less">
    @import '../../less/variables';

</style>
