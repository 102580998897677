<script lang="ts">
    import Vue from 'vue';
    import moment from 'moment';

    export default Vue.extend({
        data(){
            return {
                showPauseProgress: false,
                showResumeProgress: false,
                showCancelProgress: false
            }
        },
        computed: {
            subscription(){
                return this.$store.state.subscriptions.document;
            },
            active(){
              return this.isSubscribed && !this.canceled && !this.cancelling
            },
            isSubscribed(){
                return this.subscription.id
            },
            cancelling(){
                return this.subscription.cancel_at_period_end
            },
            canceled(){
              return this.subscription.status === 'canceled';
            },
            paused(){
              return !this.cancelling && this.subscription.paused || false
            },
            paymentMethod(){
                return this.$store.state.paymentMethods.document;
            },
            discount(){
                return this.subscription && this.subscription.discount;
            },
            resumeDate(){
                if(this.paused){
                    const date = moment(this.subscription.resumes_at * 1000);
                    return date.format('dddd MMMM Do YYYY')
                }
                return null
            },
            nextBillingDate(){
                const date = moment(this.subscription.current_period_end * 1000);
                return date.format('dddd MMMM Do YYYY')
            },
            originalAmountInDollars(){
                return this.subscription.amount / 100;
            },
            amountInDollars(){
                let dollars = this.originalAmountInDollars;
                if(this.discount){
                    if(this.discount.percent_off){
                        return dollars * (this.discount.percent_off / 100);
                    }
                    if(this.discount.amount_off){
                        return dollars - this.discount.amount_off;
                    }
                }
                return dollars;
            },
            savingsInDollars(){
                return this.originalAmountInDollars - this.amountInDollars;
            },
            discountReadable(){
                if(this.discount && this.discount.percent_off){
                    return `${this.discount.percent_off}% off`
                }
                if(this.discount && this.discount.amount_off){
                    return `$${this.discount.amount_off} off`
                }
                return '';
            },
            isDiscountForever(){
                return this.discount && this.discount.duration === 'forever'
            },
            keepButtonLabel(){
                let label = 'Keep StoryFolder'
                if(this.discount){
                    if(this.isDiscountForever){
                        label += ' with Permanent Discount'
                    }else{
                        label += ' with Discount'
                    }
                }
                return label
            }
        },
        methods: {
            formatMoney(valueInDollars){
                return `$${Number(valueInDollars).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`
            },
            pauseSubscription(){
                console.log('definitely dispatching pause now');
                this.showPauseProgress = true;
                this.$store.dispatch('subscriptions/pause')
                    .then(() => {
                        console.log('good');
                    })
                    .catch(() => {
                        console.log('failed');
                    })
                    .finally(() => {
                        console.log('finally');
                        this.showPauseProgress = false
                        this.$router.push({name: 'AccountSubscriptionView'});
                     });
            },
            resumeSubscription(){
              if(this.canceled){
                console.log('Start new subscription')
                this.$store.dispatch('subscriptions/checkout', this.subscription.interval)
              }else{
                this.showResumeProgress = true;
                this.$store
                    .dispatch('subscriptions/resume')
                    .finally(() => {
                      this.showResumeProgress = false
                      this.$router.push({name: 'AccountSubscriptionView'});
                    });
              }
            },
            cancelSubscription(){
                this.showCancelProgress = true;
                this.$store
                    .dispatch('subscriptions/cancel')
                    .finally(() => {
                        this.showCancelProgress = false
                        this.$router.push({name: 'AccountSubscriptionView'});
                     });
            },
            claimMachine(){
                this.showClaimMachinesProgress = true;
                this.$store
                    .dispatch('users/claimMachine')
                    .finally(() => {
                        this.showClaimMachinesProgress = false
                        this.$router.push({name: 'AccountUserView'});
                     });
            }
        }
    });
</script>
