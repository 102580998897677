<template>
  <div>
    <div class="input-container">
      <label for="name">Name</label>
      <InputText
          name="name"
          :value="formData.name"
          placeholder="Shot Whatever"
      />
    </div>
    <div class="input-container">
      <label for="description">Description</label>
      <InputText
          name="description"
          :value="formData.description"
          placeholder="Describe the purpose of this assistant"
      />
    </div>
    <div class="input-container">
      <label for="description">Model</label>
      <InputText
          name="model"
          :value="formData.model"
          placeholder="Which model should this assistant run on?"
      />
    </div>
    <div class="input-container">
      <label for="description">API Version</label>
      <InputText
          name="apiVersion"
          :value="formData.apiVersion"
          placeholder="Which version of the API should this use?"
      />
    </div>
    <div class="input-container">
      <label for="persona">Persona</label>
      <InputTextArea
          name="persona"
          :value="formData.persona"
          placeholder=""
      />
    </div>
    <div class="input-container">
      <label for="goal">Goal</label>
      <InputTextArea
          name="goal"
          :value="formData.goal"
          placeholder=""
      />
    </div>
    <div class="input-container">
      <label for="methodology">Methodology</label>
      <InputTextArea
          name="methodology"
          :value="formData.methodology"
          placeholder=""
      />
    </div>
    <div class="input-container">
      <label for="referencesTemplate">Resources Template</label>
      <InputTextArea
          name="referencesTemplate"
          :value="formData.referencesTemplate"
          placeholder=""
      />
    </div>
    <div class="input-container">
      <label for="requestSettings">Request Settings</label>
      <InputJson
        name="requestSettings"
        :value="formData.requestSettings"
      />
    </div>
    <div class="input-container">
      <label for="tools">Tools</label>
      <InputJson
        name="tools"
        :value="formData.tools || []"
      />
    </div>
    <Button @click="validateAndSubmit">Submit</Button>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import MixinForm from "@/components/MixinForm.vue";

export default Vue.extend({
  mixins: [MixinForm],
});
</script>
<style scoped lang="less">
@import '../../less/variables';
@import '../../less/form-layout';
.input-container{
  margin-bottom: 1em;
}
</style>
