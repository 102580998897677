<template>
    <div>
        <canvas ref="canvas" width="400" height="400" />
    </div>
</template>
<script lang="ts">
    import Chart from 'chart.js/auto'
    import Vue from 'vue';

    export default Vue.extend({
        props: {
            datasets: {
                type: Array
            },
            type: {
                type: String,
                default: 'pie'
            }
        },
        data(){
            return {
                chart: null
            }
        },
        computed: {
            labels(){
                return this.datasets[0].map(date => date.label);
            },
            datasetsWithConfiguration(){
                const colors = [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)'
                ];
                return this.datasets.map((dataset, index) => {
                  console.log({
                    dataset, index
                  });
                  const data = dataset.map(date => date.value)
                  console.log(data);
                    return {
                      label: !index ? 'Current Period' : 'Previous Period',
                      data,
                      borderWidth: 1,
                      backgroundColor: colors,
                      borderColor: colors,
                    }
                })
            }
        },
        watch: {
            datasetsWithConfiguration: {
                immediate: false,
                deep: true,
                handler(){
                    this.draw()
                }
            }
        },
        mounted(){
            this.draw()
        },
        methods:{
            draw(){
                if(this.chart){
                    this.chart.destroy()
                }
                this.chart = new Chart(this.$refs.canvas, {
                    type: this.type,
                    data: {
                      labels: this.labels,
                      datasets: this.datasetsWithConfiguration
                    },
                    options: {
                      animation: {
                        duration: 0
                      },
                      layout: {
                        padding: {
                          left: 0,
                          right: 0,
                          top: 0,
                          bottom: 0
                        }
                      },
                      legend: {
                        display: false
                      },

                    }
                });
            }
        }
    });
</script>
<style scoped lang="less">
canvas {
    width: 100%;
    max-height: 300px !important;
}
</style>
