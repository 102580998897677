<template>
  <div>
    <AccountSection title="Assistants" :padded="true">
      <div v-for="assistant in assistants" :key="assistant._id">
        <router-link :to="{name: 'AccountAdminChatAssistantSettings', params: {assistantId: assistant._id}}">
          <span class="bold">{{assistant.name}}</span><br />
          <span class="description">{{assistant.description}}</span>
        </router-link>
      </div>
      <Button :to="{name: 'AccountAdminChatAssistantSettings', params: {assistantId: 'new'}}">+ New Assistant</Button>
    </AccountSection>
    <AccountSection title="Threads" :padded="true">
      ok
    </AccountSection>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import AccountSection from '../molecules/AccountSection.vue';
import Button from "@/components/atoms/Button.vue";

export default Vue.extend({
  components: {
    Button,
    AccountSection
  },
  computed: {
    assistants(){
      return this.$store.getters['assistants/all']
    }
  },
  created(){
    this.$store.dispatch('assistants/load')
  }
});
</script>
<style scoped lang="less">
@import '../../less/variables';
a {
  color: @black;
}
</style>
