<template>
    <AccountSection title="Subscription">
        <div class="title">
            <IconSvg name="circled-checkmark" />
            Your subscription has been reactivated!
        </div>
        <p class="primary">
          Your subscription will automatically renew at {{nextBillingDate}} and you'll be charged {{formatMoney(amountInDollars)}}.
        </p>
        <p v-if="savingsInDollars">
            Your discount <span class="tag discount-code">{{subscription.discount.id}}</span> is saving you {{formatMoney(savingsInDollars)}} every {{subscription.interval}}.
        </p>
        <Button label="My Account" :to="{name: 'AccountUserView'}" />
    </AccountSection>
</template>
<script lang="ts">
    import Vue from 'vue';
    import AccountSection from '../molecules/AccountSection.vue';
    import AccountSubscriptionMixin from '../mixins/AccountSubscriptionMixin.vue';
    import Button from '../atoms/Button.vue';
    import IconSvg from '../atoms/IconSvg.vue';

    export default Vue.extend({
        components: {
            AccountSection,
            Button,
            IconSvg
        },
        mixins: [AccountSubscriptionMixin],
        created(){
            this.$store.dispatch('subscriptions/load')
        }
    });
</script>
<style scoped lang="less">
    @import '../../less/variables';
    /deep/ section{
        text-align: center;
        padding: 3em 3em;
    }
    .title{
        font-size: 2em;
        font-weight: bolder;
        margin-bottom: 1em;
        color: @green;
        .icon{
            font-size: 2em;
        }
    }
    .discount-code{
        font-weight: bolder;
        color: @green;
    }
</style>
