<template>
  <div>
    <AccountSection title="Download">
      <Button class="special" label="Download StoryFolder" icon="arrow" @click="$router.push({name: 'download'})"/>
    </AccountSection>
  </div>
</template>
<script lang="ts">
    import Vue from 'vue';
    import AccountSection from '../molecules/AccountSection.vue';
    import AccountSubscriptionMixin from '../mixins/AccountSubscriptionMixin.vue';
    import Button from "@/components/atoms/Button.vue";

    export default Vue.extend({
      components: {
        Button,
          AccountSection
      },
      mixins: [AccountSubscriptionMixin],
      computed: {
        subscribed(){
          return this.$store.getters['subscriptions/isSubscribed']
        }
      }
    });
</script>
<style scoped lang="less">
    @import '../../less/variables';
    /deep/ .platform{
      color: @grayMedium;
    }
    .key{
      border:3px dashed @grayMedium;
      display: inline-block;
      margin:0 auto;
      padding: .5em 1em;
      border-radius: 0.5em;
      margin-bottom: 1em;
    }
</style>
