import { render, staticRenderFns } from "./BaseModal.vue?vue&type=template&id=2f556475&scoped=true"
import script from "./BaseModal.vue?vue&type=script&lang=ts"
export * from "./BaseModal.vue?vue&type=script&lang=ts"
import style0 from "./BaseModal.vue?vue&type=style&index=0&id=2f556475&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f556475",
  null
  
)

export default component.exports