<template>
  <div>
    <AccountSection title="Admin Dashboard">
      <div style="display: flex; gap: 1em; align-items: center">
        <select v-model="interval">
          <option value="day">Daily</option>
          <option value="week">Weekly</option>
          <option value="month">Monthly</option>
          <option value="quarter">Quarterly</option>
          <option value="year">Annual</option>
        </select>
        <div style="margin-left: auto;display: flex; gap: 1em;">
          <Button
              @click="showPieCharts=!showPieCharts"
              :label="`${showPieCharts ? 'Hide' : 'Show'} pies`"
              icon="graph"
              style="margin: 0;"
              size="small"
          />
          <Button
              @click="showCustomersOnly=!showCustomersOnly"
              :label="`${showCustomersOnly ? 'Show All Users' : 'Show Customers Only'}`"
              icon="user"
              style="margin: 0;"
              size="small"
          />
          <Button
            @click="refresh"
            style="margin: 0; padding: 1em !important; white-space: normal"
            size="small"
          >
            <IconSvg name="refresh" style="margin: 0 !important;"/>
          </Button>
        </div>
      </div>
      <div v-if="storyboards" :class="{showCustomersOnly}">
        <h2>Users</h2>
        <ChartLine :datasets="datasetsForType('selectedUserSet')" />
        <ChartPie :datasets="customJourneyPieDataset()" type="bar"/>
        <div v-if="showPieCharts">
          <ChartPie :datasets="pieDatasetsForType('selectedUserSet', ['jobTitle'])"  type="bar"/>
          <ChartPie :datasets="pieDatasetsForType('selectedUserSet', ['customerType'])"  type="bar"/>
          <ChartPie :datasets="pieDatasetsForType('selectedUserSet', ['reasonForSigningUp'])"  type="bar"/>
          <ChartPie :datasets="pieDatasetsForType('selectedUserSet', ['jobTitle', 'reasonForSigningUp'])" type="bar"/>
        </div>
        <table>
          <tr
              class="document"
              v-for="(user, index) in getDocsForPeriod('selectedUserSet', start, end)"
              :key="index"
          >
            <td>
              <p>
                <small>
                  {{createdTime(user.createdAt)}}
                </small><br />
                <strong> {{user.fullName}} </strong>
                <small> {{user.email}} </small>
                <br />
                {{user.customJobTitle || user.jobTitle}}
                <span v-if="user.customerType">
                  in <strong>{{user.customCustomerType || user.customerType}}</strong>
                </span>
                <span v-if="user.companyName">
                  working for <strong>{{user.companyName}}</strong>
                </span>
                {{user.customReasonForSigningUp || user.reasonForSigningUp}}
                <br v-if="user.postPurchaseSurvey">
                <small>
                  {{user.postPurchaseSurvey}}
                </small>
              </p>

            </td>
            <td>
              <div class="journey">
                <IconSvg v-if="user.tags.includes('mac')" name="apple" v-tooltip="`macOs`"/>
                <IconSvg v-if="user.tags.includes('windows')" name="windows" v-tooltip="`Windows`" />
                <IconSvg name="tag" v-tooltip="user.tags.filter(tag => !journey.includes(tag)).join(', ')" />
              </div>
            </td>
            <td>
              <div class="journey">
                <IconSvg
                    v-for="step in journey"
                    :key="step"
                    :class="{step: true, complete: user.tags.includes(step)}"
                    :name="user.tags.includes(step) ? 'check':'x'"
                    v-tooltip="step"
                />
                <IconSvg
                    :name="user.subscribed ? 'check':'x'"
                    :class="{green: user.subscribed, red: !user.subscribed}"
                />
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div v-if="storyboards">
        <h2>Storyboards</h2>
        <ChartLine :datasets="datasetsForType('storyboards')" />
        <table>
          <tr
              class="document"
              v-for="(storyboard, index) in getDocsForPeriod('storyboards', start, end)"
              :key="index"
          >
            <td>{{createdTime(storyboard.createdAt)}}</td>
            <td>
              <a :href="`/b/${storyboard._id}`" target="_blank">{{storyboard.title.substr(0,50)}}...</a>
            </td>
          </tr>
        </table>
      </div>

      <div v-if="logfiles">
        <h2>Log files</h2>
        <ChartLine :datasets="datasetsForType('logfiles')" />
        <iframe class="logfile" ref="logfile">

        </iframe>
        <table>
          <tr
            class="document"
            v-for="(logfile, index) in getDocsForPeriod('logfiles', start, end)"
            :key="index"
          >
            <td>{{createdTime(logfile.createdAt)}}</td>
            <td>
              {{user(logfile.user)}}
            </td>
            <td>
              {{logfile.platform}}
            </td>
            <td>
              <a v-for="file in logfile.files" :key="file.key" :href="`/api/log-files/download/${file.key}`" target="_blank" @click.stop.prevent="openLogfile"> {{file.key.replace(/\d+-|\.log$/g, '')}} </a>
            </td>
          </tr>
        </table>
      </div>
    </AccountSection>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import AccountSection from '../molecules/AccountSection.vue';
import AccountSubscriptionMixin from '../mixins/AccountSubscriptionMixin.vue';
import ChartLine from "@/components/molecules/ChartLine.vue";
import IconSvg from "@/components/atoms/IconSvg.vue";
import Button from "@/components/atoms/Button.vue";
import ChartPie from "@/components/molecules/ChartPie.vue";
const axios = require('axios');
const moment = require('moment');

export default Vue.extend({
  components: {
    ChartPie,
    Button,
    IconSvg,
    ChartLine,
    AccountSection
  },
  mixins: [AccountSubscriptionMixin],
  data(){
    return {
      end: null,
      interval: 'month',
      users: null,
      storyboards: null,
      logfiles: null,
      showPieCharts: false,
      showCustomersOnly: false,
      dataTypes: ['users', 'storyboards', 'logfiles'],
      journey: [
        'Created',
        'Downloaded app',
        'Activated app',
        'Imported',
        'Viewed Storyboard',
        'Uploaded',
        'Exported',
        'Customer'
      ]
    }
  },
  computed:{
    start(){
      return moment(this.end).clone().subtract(1, this.interval)
    },
    previousPeriodStart(){
      return this.start.clone().subtract(1, this.interval)
    },
    previousPeriodEnd(){
      return this.start.clone().subtract(1,'second')
    },
    usersWithTrials(){
      return (this.users || []).filter(user => user.subscribed);
    },
    selectedUserSet(){
      return this.showCustomersOnly ? this.usersWithTrials : this.users;
    }
  },
  watch: {
    interval: {
      immediate: true,
      handler(){
        this.end = moment().add('1','day')
      }
    }
  },
  mounted() {
    this.loadMultipleTypes(this.dataTypes, false);
  },
  methods: {
    refresh(){
      this.loadMultipleTypes(this.dataTypes, true);
    },
    loadMultipleTypes(types, force){
      types.forEach(type => {
        this.loadType(type, force)
      })
    },
    async loadType(type, force){
      force = force || false;
      this[type] = JSON.parse(localStorage.getItem(type)) || [];
      if(force || this[type].length === 0){
        let api = type === 'logfiles' ? 'log-files' : type;
        this[type] = (await axios.get(`/api/${api}`)).data
        localStorage.setItem(type, JSON.stringify(this[type]))
      }
    },
    getDocsForPeriod(type, start, end){
      return (this[type] || []).filter(document => {
        return moment(document.createdAt).isBetween(start,end)
      }).sort((a,b) => {
        return moment(b.createdAt).diff(moment(a.createdAt))
      })
    },
    getPieForPeriod(type, fields, start, end){
      const docs = this.getDocsForPeriod(type, start, end);
      const values = {};
      docs
          .filter(doc => {
            return fields.some(field => doc[field])
          })
          .forEach(doc => {
            const value = fields.map(field => doc[field]).join(' ') || 'NA';
            if(!values[value]){
              values[value] = 0;
            }
            values[value]++;
          });
      return Object.keys(values).map((key) => {
        return {
          label: `${Math.round(100/docs.length * values[key])}% ${key}`,
          value: values[key]
        }
      }).sort((a,b) => {
        return b.value - a.value
      })
    },
    getChartForPeriod(type, start, end){
      const numberOfDays = Math.round(moment.duration(moment(end).diff(moment(start))).asDays());
      const docs = this.getDocsForPeriod(type, start, end);
      let cumulativeDocCount = 0;
      return new Array(numberOfDays).fill(0).map((v, index) => {
        const date = start.clone().add(index, 'day');
        const docsToday = docs.filter(doc => {
          return moment(doc.createdAt).isSame(date, 'day')
        }).length
        cumulativeDocCount+= docsToday;
        return {
          date,
          value: cumulativeDocCount
        }
      });
    },
    datasetsForType(type){
      return [
        this.getChartForPeriod(type, this.start, this.end),
        this.getChartForPeriod(type, this.previousPeriodStart, this.previousPeriodEnd)
      ]
    },
    pieDatasetsForType(type, field){
      return [
        this.getPieForPeriod(type, field, this.start, this.end)
      ]
    },
    customJourneyPieDataset(){
      const docs = this.getDocsForPeriod('users', this.start, this.end);
      const values = {};
      this.journey.forEach(step => {
        values[step] = 0;
      });
      docs.forEach(doc => {
        this.journey.forEach(step => {
          if(doc.tags.includes(step)){
            values[step]++;
          }
        });
      });
      console.log({values});
      const dataset = Object
          .keys(values)
          .map(key => {
            return {
              label: `${Math.round(100/docs.length * values[key])}% ${key}`,
              value: values[key]
            }
          })
          .sort((a,b) => {
            return b.value - a.value
          })
      console.log({ dataset });
      return [ dataset ]
    },
    createdTime(date){
      return moment(date).fromNow()
    },
    openLogfile(e){
      const url = e.target.href;
      this.$refs.logfile.src = url;
    },
    user(userId){
      if(userId){
        const user = this.users.find(user => user._id === userId);
        return user ? `${user.fullName} ${user.email}` : '';
      }
      return '';
    }
  }
});
</script>
<style scoped lang="less">
@import '../../less/variables';
/deep/ tr{
  padding: 0.5em 1em;
  font-size: 0.8em;
  color: @grayDark;
  border:3px solid red;
  a{
    color: @grayDark;
  }
}
table p{
  font-size: 1rem;
  color: @grayDark;
  margin-bottom: 0.5em;
}
.green{
  color: @green;
}
.red{
  color: @red;
}

.journey{
  display: flex;
  gap: 0.5em;
}
.step{
  color: @grayLightMedium;
  &.complete{
    color: @green;
  }
}
iframe{
  width: 100%;
  height: 500px;
}
</style>
