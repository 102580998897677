<template>
  <header class="page-header">
    <slot />
  </header>
</template>
<script lang="ts">
import Vue from 'vue'
export default Vue.extend({})
</script>
<style lang="less" scoped>
  @import '../../less/variables';

  .page-header{
    height: @mobile-header-height;
    top: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    box-sizing: border-box;
    background-color: @white;
    box-shadow: 0px 5px 15px rgba(49, 10, 59, 0.15);
    position: fixed;
    width: 100%;
    z-index: 1;
    background-size: cover;
    background-position: center center;
  }

  h1:last-child{
    margin-bottom: 56px;
  }
  h1, h4{
    margin-right: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h1{
    font-size: 22px;
    padding-left: 1.5rem;
    margin: 0 0 0rem 0;
    font-weight: 600;
  }
  a {
    margin-left: 1.20rem;
    margin-bottom: 0.25rem;
    color: @purpleVibrant;
    &:hover, &:active {
      text-decoration: none;
      color: @purpleDark;
    }
    /deep/ .icon.chevron {
      margin-top: -1px;
    }
  }

  @media (min-width: @firstbreakpoint){
    .page-header {
      position: relative;
      height: 130px;
      margin-bottom: 1.5em;
      background-color: transparent;
      box-shadow: none;
      pointer-events: none;
      padding: 0 1.5rem;
      > * {
        pointer-events: all;
      }
    }
    h1{
      font-size: 30px;
      padding-left: 0rem;
      margin-top: 0;
      font-weight: 500;
    }
    a {
      pointer-events: all;
      margin-left: -0.18rem;
      font-size: 0.9em;
      color: @grayMedium;
    }
  }
</style>
