<template>
  <div>
    <AccountSection title="Assistant Settings" :padded="true">
      <FormAssistantSettings
        class="form-dark"
        :initial-form-data="assistant"
        :create="assistant => $store.dispatch('assistants/create', assistant)"
        :update="assistant => $store.dispatch('assistants/update', assistant)"
      />
    </AccountSection>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import AccountSection from '../molecules/AccountSection.vue';
import MixinForm from "@/components/MixinForm.vue";
import FormAssistantSettings from "@/components/organisms/FormAssistantSettings.vue";

export default Vue.extend({
  mixins: [MixinForm],
  components: {
    FormAssistantSettings,
    AccountSection
  },
  computed: {
    assistants(){
      return this.$store.getters['assistants/all']
    },
    assistant(){
      return this.$store.getters['assistants/byId'](this.$route.params.assistantId);
    }
  },
  created(){
    this.$store.dispatch('assistants/load')
  }
});
</script>
<style scoped lang="less">
@import '../../less/variables';
@import '../../less/form-layout';
</style>
