<template>
    <AccountSection title="Account">
        <p>Name</p>
        <h2>{{$store.state.user.user.name || "-"}}</h2>
        <p>Email</p>
        <h2>{{$store.state.user.user.email}}</h2>
        <p>Activation / Pro Key</p>
        <h2>{{$store.state.user.user.apiKey}}</h2>
          <p>Maximum Activated Licenses</p>
          <h2>{{$store.state.user.user.maximumNumberOfMachines}}</h2>
        <p>Joined</p>
        <h2>{{joined}}</h2>
        <div v-if="credit">
            <p>Available Account Credit</p>
            Get $10 in credit when a friend joins. Email jeff@jeffjassky.com when they join to have it applied.
            <h2>${{credit}}</h2>
        </div>

    </AccountSection>
</template>
<script lang="ts">
    import Vue from 'vue';
    import moment from 'moment';
    import AccountSection from '../molecules/AccountSection.vue';
    import AccountSubscriptionMixin from '../mixins/AccountSubscriptionMixin.vue';

    export default Vue.extend({
        components: {
            AccountSection,
        },
        mixins: [AccountSubscriptionMixin],
        computed: {
            joined(){
                return moment(this.$store.state.user.user.createdAt).format('MMMM Do YYYY, h:mm a')
            },
            credit(){
                if("balance" in this.$store.state.accounts.document){
                    return Number((this.$store.state.accounts.document.balance * -1) / 100).toFixed(2)
                }
                return null
            }
        },
        created(){
            this.$store.dispatch('user/loadMe')
            this.$store.dispatch('accounts/load')
        }
    });
</script>
<style scoped lang="less">
    @import '../../less/variables';
    h2{
        margin-top: 0;
    }
    p{
    margin-bottom: 0;
    }
</style>
