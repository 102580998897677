<template>
    <AccountSection title="Subscription">
        <h2>Sorry to see you go!</h2>
        <br />


        <div class="title">
            <IconSvg name="circled-checkmark" />
            Your subscription has been cancelled.
        </div>
        <p class="primary">
            You can <span class="bold">re-activate</span> your subscription before {{nextBillingDate}}
            <span v-if="savingsInDollars">
                and keep your <span class="bold">{{isDiscountForever ? 'permanent' :''}} {{discountReadable}} discount</span> and continue to save <span class="bold">{{formatMoney(savingsInDollars)}}</span> every {{subscription.interval}}.
            </span>
        </p>
        <br />
        <Button label="Re-activate my subscription and keep my forever discount" @click="reactivateSubscription" />
    </AccountSection>
</template>
<script lang="ts">
    import Vue from 'vue';
    import AccountSection from '../molecules/AccountSection.vue';
    import AccountSubscriptionMixin from '../mixins/AccountSubscriptionMixin.vue';
    import Button from '../atoms/Button.vue';
    import IconSvg from '../atoms/IconSvg.vue';

    export default Vue.extend({
        components: {
            AccountSection,
            Button,
            IconSvg
        },
        mixins: [AccountSubscriptionMixin],
        created(){
            this.$store.dispatch('subscriptions/load')
        },
        methods: {
            reactivateSubscription(){
                // this.$store.dispatch('user/reactivateSubscription')
                this.$router.push({name: 'AccountSubscriptionReactivated'});
            }
        }
    });
</script>
<style scoped lang="less">
    @import '../../less/variables';
    /deep/ section{
        text-align: center;
        padding: 3em 3em;
    }
    .title{
        font-size: 2em;
        font-weight: bolder;
        margin-bottom: 1em;
        .icon{
            font-size: 2em;
        }
    }
    .discount-code{
        font-weight: bolder;
        color: @green;
    }
</style>
