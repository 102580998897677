var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',{staticClass:"segmented-control"},_vm._l((_vm.options),function(option,index){return _c('li',{key:index,class:{
      option: true,
      selected: _vm.value === option
    },on:{"click":function($event){return _vm.$emit('change', option)}}},[_vm._t("default",null,null,{
      option,
      value: _vm.value
    })],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }