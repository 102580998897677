<template>
    <div :class="{ center, padded }">
        <h2 v-if="title">{{title}}</h2>
        <section>
            <slot />
        </section>
    </div>
</template>
<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        props: {
            title: {
                type: String,
                required: false
            },
            center: {
                type: Boolean,
                default: false
            },
            padded: {
                type: Boolean,
                default: false
            }
        }
    });
</script>
<style scoped lang="less">
    @import '../../less/variables';

    .center section {
        text-align: center;
        p{
            margin-right: auto;
            margin-left: auto;
            max-width: 700px;
        }
    }
    .padded section {
        padding: 3em;
    }
    section {
        background: white;
        border-radius: 0.75em;
        padding: 1em 3em;
        margin-bottom: 2em;
    }
    p{
        &.primary{
            color: black;
        }
    }

    .title{
        font-size: 2em;
        font-weight: bolder;
        margin-bottom: 1em;
        .icon{
            font-size: 2em;
        }
    }
    .discount-code{
        font-weight: bolder;
        color: @green;
    }
    button {
        white-space: pre-wrap;
    }
    button + button{
        margin: 2em;
    }
    span.tag{
        background: rgba(0,0,0,0.05);
        border:1px solid rgba(0,0,0,0.05);
        display: inline-block;
        padding: 0.25em 0.75em;
        border-radius: 0.25em;
    }
    hr{
        margin-right: -3em;
        margin-left: -3em;
        border: 0;
        border-top: 1px solid rgba(0,0,0,0.2);
    }
</style>
