<template>
    <div>
        <AccountSection title="Before you go..." :center="true" :padded="true">
            <h2>Here are some options for you:</h2>

            <p v-if="savingsInDollars && isDiscountForever" class="primary">
                If you decide to cancel:<br /><br />
                1. You will lose access to all StoryFolder features.<br />
                2. You will lose your subscriptions <span class="bold">permanent {{discountReadable}} discount.</span>
            </p>
            <p v-if="savingsInDollars && !isDiscountForever" class="primary">
                Your discount <span class="tag discount-code">{{subscription.discount.id}}</span>
                is saving you {{formatMoney(savingsInDollars)}} every {{subscription.interval}}.
            </p>

            <Button :label="keepButtonLabel" :to="{name:'AccountSubscriptionKeep'}" class="special uppercase" icon="circled-checkmark"/><br />
            <Button v-if="!paused" @click="pauseSubscription">
                Pause my subscription for 3 months
                <ProgressSpinner v-if="showPauseProgress" />
            </Button>
            <hr />
            <Button @click="cancelSubscription" class="outline warning">
                {{buttonLabel}}
                <ProgressSpinner v-if="showCancelProgress" />
            </Button>
        </AccountSection>
    </div>
</template>
<script lang="ts">
    import Vue from 'vue';
    import AccountSection from '../molecules/AccountSection.vue';
    import AccountSubscriptionMixin from '../mixins/AccountSubscriptionMixin.vue';
    import Button from '../atoms/Button.vue';
    import ProgressSpinner from '../atoms/ProgressSpinner.vue';

    export default Vue.extend({
        components: {
            AccountSection,
            Button,
            ProgressSpinner
        },
        mixins: [AccountSubscriptionMixin],
        computed: {
            buttonLabel(){
                let label = `End my Subscription ${this.nextBillingDate}`;
                if(this.isDiscountForever){
                    label += ' and forego my permanent discount'
                }
                return label + '.'
            }
        },
        created(){
            this.$store.dispatch('subscriptions/load')
        },
    });
</script>
<style scoped lang="less">
    @import '../../less/variables';
    button.warning {
        margin-top: 2em;
        margin-bottom: 0;
    }
</style>
