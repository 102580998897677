<template>
    <div>
        <AccountSection title="Subscription" :center="true" :padded="true" v-if="isSubscribed">
                <h3>
                    <span v-if="active">
                        Your Active Subscription:
                    </span>
                    <span v-if="paused">
                        Your subscription is paused:
                    </span>
                    <span v-if="cancelling">
                        You can still reactivate your subscription:
                    </span>
                    <span v-if="canceled">
                        Restart your subscription:
                    </span>
                </h3>

                <div :class="{title: true, green: active}">
                    <IconSvg name="circled-checkmark" v-if="active"/>
                    {{subscription.name}}
                </div>

                <p class="primary">
                  <span v-if="active">
                    Your subscription will automatically renew {{nextBillingDate}} for {{formatMoney(amountInDollars)}}.
                  </span>
                  <span v-if="paused && !cancelling">
                    Your subscription will automatically reactivate {{resumeDate}} for {{formatMoney(amountInDollars)}}/{{subscription.interval}}.
                  </span>
                  <span v-if="cancelling">
                    Your subscription is scheduled to end {{nextBillingDate}}. You will receive no further charges.
                  </span>
                  <span v-if="canceled">
                    Your subscription has ended and you will receive no further charges.
                  </span>
                </p>
                <p v-if="savingsInDollars">
                  <span v-if="!paused && !cancelling">
                    Your discount <span class="tag discount-code">{{discount.id}}</span> is saving you {{formatMoney(savingsInDollars)}} every {{subscription.interval}}.
                  </span>
                  <span v-if="paused && !cancelling">
                    Your discount <span class="tag discount-code">{{discount.id}}</span> will you {{formatMoney(savingsInDollars)}} every {{subscription.interval}} when you reactivate.
                  </span>
                  <span v-if="cancelling">
                    Keep your discount <span class="tag discount-code">{{discount.id}}</span> save {{formatMoney(savingsInDollars)}} every {{subscription.interval}} by reactivating before {{nextBillingDate}}.
                  </span>
                </p>
                <div v-if="paused || cancelling || canceled">
                    <Button @click="resumeSubscription" class="special uppercase" icon="refresh">
                        Reactivate Subscription
                        <ProgressSpinner v-if="showResumeProgress" />
                    </Button>
                </div>
        </AccountSection>
        <div v-else>
            <h2 style="text-align: center">Join the beta now and get <span class="tag green bold">50% off forever</span>.</h2>
            <FormSelectInterval />
        </div>
        <Button v-if="isSubscribed" label="Manage Subscription" :to="{name:'AccountSubscriptionManage'}" :class="paused ? 'outline' : null"/>
    </div>
</template>
<script lang="ts">
    import Vue from 'vue';
    import AccountSection from '../molecules/AccountSection.vue';
    import FormSelectInterval from '../organisms/FormSelectInterval.vue';
    import AccountSubscriptionMixin from '../mixins/AccountSubscriptionMixin.vue';
    import Button from '../atoms/Button.vue';
    import IconSvg from '../atoms/IconSvg.vue';
    import ProgressSpinner from '../atoms/ProgressSpinner.vue';

    export default Vue.extend({
        components: {
            AccountSection,
            Button,
            IconSvg,
            ProgressSpinner,
            FormSelectInterval
        },
        mixins: [AccountSubscriptionMixin],
        created(){
            this.$store.dispatch('subscriptions/load')
        }
    });
</script>
<style scoped lang="less">
    @import '../../less/variables';

</style>
