<template>
  <div>
    <AccountSection v-if="$store.getters['machines/available'] < 1">
      <h4>
        <IconSvg name="notice" /> You have reached the maximum number of active licenses.
      </h4>
      <p>
        To activate a new license, you must first deactivate an existing license.
      </p>
    </AccountSection>
    <AccountSection title="Activated Licenses" :padded="true">
      <div v-if="machines.length > 0">
        <div class="machine header">
          <p>Name</p>
          <p>Date activated</p>
        </div>
        <div v-for="(machine, index) in machines" :key="index" class="machine">
          <p class="bold">{{machine.info ? machine.info.model : 'na'}}</p>
          <p>{{moment(machine.createdAt).format("l")}}</p>
          <p>
            <a @click="$store.dispatch('machines/delete', machine._id)">
              Deactivate
              <IconSvg name="x" />
            </a>
          </p>
        </div>
      </div>
      <p v-else>
        You have zero machines activated.
      </p>
    </AccountSection>
  </div>
</template>
<script lang="ts">
    import Vue from 'vue';
    import moment from 'moment';
    import AccountSection from '../molecules/AccountSection.vue';
    import IconSvg from '../atoms/IconSvg.vue';

    export default Vue.extend({
        components: {
            AccountSection,
            IconSvg
        },
        computed: {
            machines(){
                return this.$store.getters['machines/all']
            }
        },
        methods: {
            moment
        },
        created(){
            this.$store.dispatch('machines/load')
        }
    });
</script>
<style scoped lang="less">
    @import '../../less/variables';
    .machine{
        display: grid;
        grid-template-columns: 1fr 1fr 140px;
        gap: 2em;
        p{
            color: black;
            margin: .25em 0;
        }
        a{
            color: @grayMedium;
            &:hover{
                color: black;
            }
        }

    }
    .header p{
        color: @grayMedium;
    }
    .error p{
      color: @orange;
    }
</style>
