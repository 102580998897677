<template>
  <ul class="segmented-control">
    <li
      v-for="(option, index) in options"
      :key="index"
      @click="$emit('change', option)"
      :class="{
        option: true,
        selected: value === option
      }"
    >
      <slot v-bind="{
        option,
        value
      }"/>
    </li>
  </ul>
</template>
<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    props: {
      value: {
        type: Object
      },
      options: {
        type: Array,
        default: () => []
      }
    }
  });
</script>
<style scoped lang="less">
  @import "../../less/variables";
  ul {
    padding: 0;
    margin:0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .option{
    margin:0;
    color: #999;
    padding: 0.5em 1.25em;
    font-weight: 500;
    list-style: none;
    background: fade(@grayMedium, 20%);
    &.selected{
      background: @purpleVibrant;
      color: white;
    }
    &:first-child{
      border-top-left-radius: 1.5em;
      border-bottom-left-radius: 1.5em;
    }
    &:last-child{
      border-top-right-radius: 1.5em;
      border-bottom-right-radius: 1.5em;
    }
  }
</style>
