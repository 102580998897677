<template>
    <AccountSection title="Subscription">
        <h2 class="title green">
            <IconSvg name="circled-checkmark" />
        </h2>
        <h2 class="title">Thank you for being an early supporter!</h2>
        <p class="primary">
            For your continued support we'd like to offer you a free upgrade to <b class="green">2 activated licenses</b>.
        </p>
        <br />
        <Button label="Get my extra machine" @click="claimMachine" class="special uppercase" />
        <Button label="My Account" :to="{name:'AccountUserView'}" />
    </AccountSection>
</template>
<script lang="ts">
    import Vue from 'vue';
    import AccountSection from '../molecules/AccountSection.vue';
    import AccountSubscriptionMixin from '../mixins/AccountSubscriptionMixin.vue';
    import Button from '../atoms/Button.vue';
    import IconSvg from '../atoms/IconSvg.vue';

    export default Vue.extend({
        components: {
            AccountSection,
            Button,
            IconSvg
        },
        mixins: [AccountSubscriptionMixin],
        created(){
            this.$store.dispatch('subscriptions/load')
        }
    });
</script>
<style scoped lang="less">
    @import '../../less/variables';
    /deep/ section{
        text-align: center;
        padding: 3em 3em;
    }
</style>
