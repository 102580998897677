<template>
    <AccountSection title="Receipts" :padded="true">
        <div v-if="invoices">
            <div class="invoice header">
                <p>Date</p>
                <p>Service Period</p>
                <p class="price">Total</p>
            </div>
            <div v-for="(invoices, index) in invoices" :key="index" class="invoice">
                <p class="bold">{{moment(invoices.date * 1000).format("l")}}</p>
                <p>
                    {{moment(invoices.period_start * 1000).format("l")}} - {{moment(invoices.period_end * 1000).format("l")}}
                </p>
                <p class="price">${{Number(invoices.amount_paid / 100).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
                <p>
                    <a :href="invoices.hosted_invoice_url" target="_blank">
                        Details
                        <IconSvg name="link" />
                    </a>
                </p>
            </div>
        </div>
        <p v-else>
            You have zero receipts.
        </p>
    </AccountSection>
</template>
<script lang="ts">
    import Vue from 'vue';
    import moment from 'moment'
    import AccountSection from '../molecules/AccountSection.vue';
    import IconSvg from '../atoms/IconSvg.vue';

    export default Vue.extend({
        components: {
            AccountSection,
            IconSvg
        },
        computed: {
            invoices(){
                return this.$store.getters['invoices/all']
            }
        },
        methods: {
            moment
        },
        created(){
            this.$store.dispatch('invoices/load')
        }
    });
</script>
<style scoped lang="less">
    @import '../../less/variables';
    .invoice{
        display: grid;
        grid-template-columns: 1fr 2fr 1fr 100px;
        gap: 2em;
        p{
            color: black;
            margin: .25em 0;
        }
        a{
            color: @grayMedium;
            &:hover{
                color: black;
            }
        }
        .price{
            text-align: right;
        }
    }
    .header p{
        color: @grayMedium;
    }
</style>
