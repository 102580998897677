<template>
  <BaseModal :title="question.title" class="thing" @close="$emit('close')">
    <form class="form-outline">
      <div class="input-group" v-show="questionIndex === 1">
        <InputSelectCheckbox
          name="source"
          v-model="formData.source"
          :columns="1"
          :options="Object.values(this.options)"
          @change="onSourceChange"
        />
        <div v-show="isSelected('Social Media')">
          <label>Where on Social Media?</label>
          <InputText
              ref="socialMedia"
              name="socialMedia"
              v-model="formData.socialMedia"
              placeholder=""
          />
        </div>
        <div v-show="isSelected('Search (Google, Bing, etc)')">
          <label>What were you searching for?</label>
          <InputText
              ref="searchFor"
              name="searchFor"
              v-model="formData.searchFor"
              placeholder=""
          />
        </div>
        <div v-show="isSelected('Word of Mouth')">
          <label>Who told you about StoryFolder?</label>
          <InputText
              ref="recommendation"
              name="recommendation"
              v-model="formData.recommendation"
              placeholder=""
          />
        </div>
        <div v-show="isSelected('Advertisment')">
          <label>Where did you see the ad?</label>
          <InputText
              ref="adPlacement"
              name="adPlacement"
              v-model="formData.adPlacement"
              placeholder=""
          />
        </div>
        <div v-show="isSelected('Other')">
          <label>How'd you hear about us?</label>
          <InputText
              ref="sourceOther"
              name="sourceOther"
              v-model="formData.sourceOther"
              placeholder=""
          />
        </div>
      </div>
      <div class="input-group" v-show="questionIndex === 0">
        <label>If you hadn't purchased StoryFolder today, what would have been the reason?</label>
        <InputTextArea
          name="reasonForAlmostNotPurchasing"
          v-model="formData.reasonForAlmostNotPurchasing"
          placeholder=""
          ref="textarea"
        />
      </div>
      <div class="input-group">
        <Button
          :label="buttonLabel"
          :has-chevron="true"
          class="special"
          @click="submit"
        />
      </div>
    </form>
  </BaseModal>
</template>

<script lang="ts">
import BaseModal from "@/components/molecules/BaseModal.vue";
import MixinForm from "@/components/MixinForm.vue";

export default {
  mixins: [
    MixinForm
  ],
  components: {
    BaseModal
  },
  data(){
    return {
      val: [],
      questionIndex: 0,
      options: {
        socialMedia: 'Social Media',
        searchFor: 'Search (Google, Bing, etc)',
        recommendation: 'Word of Mouth',
        adPlacement: 'Advertisment',
        other: 'Other'
      },
      questions: [
        {
          title: `Survey`,
          subtitle: '',
        },
        {
          title: `How'd you hear about us?`,
          subtitle: ''
        },
      ]
    }
  },
  computed: {
    question(){
      return this.questions[this.questionIndex]
    },
    buttonLabel(){
      if(this.questionIndex < this.questions.length -1){
        return 'Submit'
      }
      return 'Complete';
    }
  },
  watch: {
    questionIndex(){
      if(this.questionIndex === 1){
        this.$nextTick(() => {
          this.$refs.textarea.$el.focus();
        })
      }
    }
  },
  methods: {
    submit(){
      if(this.questionIndex < this.questions.length -1){
        this.questionIndex++
      }else{
        this.$emit('close');
      }
    },
    isSelected(value){
      return (this.formData.source || []).includes(value);
    },
    onSourceChange(){
      setTimeout(() => {
        const inputs = this.$el.querySelectorAll('input, textarea')
        for (let i = 0; i < inputs.length; ++i) {
          if(inputs[i].parentNode.style.display !== 'none' && inputs[i].value == ''){
            console.log('focus on', inputs[i].focus());
            break;
          }
        }
      }, 100);
    }
  }
}
</script>

<style scoped lang="less">
@import '../../less/variables';
@import '../../less/form-layout';
.thing{
  color: black;
}
button{
  margin:0 auto;
  display: block;
}
.input-group{
  max-width: 400px;
  min-width: 250px;
  margin:0 auto;
}
/deep/ .option{
  background: @grayLight;
  padding: 0.5em 2em 0.5em 0.5em;
  width: auto !important;
  display: flex;
  border-radius: 0.5em;
}
</style>
