<template>
    <AccountSection title="Subscription" :center="true" :padded="true">
        <h3>Your {{paused ? 'Paused': 'Active'}} Subscription:</h3>
        <div :class="{title: true, green: !paused}">
          {{subscription.name}}
        </div>
        <p v-if="savingsInDollars && isDiscountForever" class="primary">
            A <span class="bold">permanent {{discountReadable}} discount</span> is locked in for your subscription.<br />
            {{paused ? 'When reactivated it will save':`It's currently saving`}} you <span class="bold">{{formatMoney(savingsInDollars)}}</span> every {{subscription.interval}}.
        </p>
        <p v-if="savingsInDollars && !isDiscountForever" class="primary">
            Your discount <span class="tag discount-code">{{subscription.discount.id}}</span>
            is saving you {{formatMoney(savingsInDollars)}} every {{subscription.interval}}.
        </p>
        <Button :label="keepButtonLabel" :to="{name:'AccountSubscriptionView'}" class="special uppercase" icon="circled-checkmark"/>
        <Button label="Proceed to cancellation" :to="{name:'AccountSubscriptionCancelConfirm'}" class="outline warning" />
    </AccountSection>
</template>
<script lang="ts">
    import Vue from 'vue';
    import AccountSection from '../molecules/AccountSection.vue';
    import AccountSubscriptionMixin from '../mixins/AccountSubscriptionMixin.vue';
    import Button from '../atoms/Button.vue';

    export default Vue.extend({
        components: {
            AccountSection,
            Button,
        },
        mixins: [AccountSubscriptionMixin],
        created(){
            this.$store.dispatch('subscriptions/load')
        }
    });
</script>
<style scoped lang="less">
    @import '../../less/variables';
    button {
        margin: 0 1em;
    }
</style>
