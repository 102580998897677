<template>
  <AppPage :show-footer="false" :show-false-footer="false" class="max-width">
    <div class="account-container">
        <aside>
            <ul>
                <li v-for="(menuItem, index) in $store.getters['navigation/account']" :key="index">
                    <component
                        :is="menuItem.component"
                        v-bind="menuItem.attributes"
                    >
                        <IconSvg :name="menuItem.icon" :class="menuItem.iconClass"/>
                        {{menuItem.slot}}
                    </component>
                </li>
                <li>
                    <a @click="$store.dispatch('user/signout')">
                        <IconSvg name="link" />
                        Sign Out
                    </a>
                </li>
            </ul>
        </aside>
        <main>
            <component :is="sectionComponent" />
        </main>
    </div>
    <ThePostPurchaseSurvey
      :initial-form-data="$store.getters['user/postPurchaseSurvey']"
      v-show="showPostPurchaseSurvey"
      @close="showPostPurchaseSurvey = false"
      :submit-on-change="true"
      :update="postPurchaseSurvey => $store.dispatch('user/update', {postPurchaseSurvey})"
      :create="postPurchaseSurvey => $store.dispatch('user/update', {postPurchaseSurvey})"
    />
  </AppPage>
</template>

<script lang="ts">
import IconSvg from "@/components/atoms/IconSvg.vue";
import AppPage from "@/components/organisms/AppPage.vue";
import AccountUserView from "@/components/organisms/AccountUserView.vue";
import AccountDownload from "@/components/organisms/AccountDownload.vue";
import AccountInvoicesView from "@/components/organisms/AccountInvoicesView.vue";
import AccountMachinesView from "@/components/organisms/AccountMachinesView.vue";
import AccountSubscriptionView from "@/components/organisms/AccountSubscriptionView.vue";
import AccountSubscriptionCancel from "@/components/organisms/AccountSubscriptionCancel.vue";
import AccountSubscriptionCancelConfirm from "@/components/organisms/AccountSubscriptionCancelConfirm.vue";
import AccountSubscriptionCancelComplete from "@/components/organisms/AccountSubscriptionCancelComplete.vue";
import AccountSubscriptionKeep from "@/components/organisms/AccountSubscriptionKeep.vue";
import AccountSubscriptionManage from "@/components/organisms/AccountSubscriptionManage.vue";
import AccountSubscriptionMigrate from "@/components/organisms/AccountSubscriptionMigrate.vue";
import AccountSubscriptionReactivated from "@/components/organisms/AccountSubscriptionReactivated.vue";
import ThePostPurchaseSurvey from "@/components/organisms/ThePostPurchaseSurvey.vue";
import AccountAdminDashboard from "@/components/organisms/AccountAdminDashboard.vue";
import AccountAdminChat from "@/components/organisms/AccountAdminChat.vue";
import AccountAdminChatAssistantSettings from "@/components/organisms/AccountAdminChatAssistantSettings.vue";


export default {
  components: {
    ThePostPurchaseSurvey,
    IconSvg,
    AppPage,
  },
  data(){
    return {
      showPostPurchaseSurvey: false,
        views: {
          AccountUserView,
          AccountInvoicesView,
          AccountDownload,
          AccountSubscriptionView,
          AccountSubscriptionManage,
          AccountSubscriptionMigrate,
          AccountSubscriptionCancel,
          AccountSubscriptionCancelConfirm,
          AccountSubscriptionCancelComplete,
          AccountSubscriptionReactivated,
          AccountSubscriptionKeep,
          AccountMachinesView,
          AccountAdminDashboard,
          AccountAdminChat,
          AccountAdminChatAssistantSettings
        }
    }
  },
  computed: {
    sectionComponent(){
        if(this.views[this.$route.name]){
            return this.views[this.$route.name]
        }
        console.error(`No account section named ${this.$route.name}`);

        // Return default view
        return AccountUserView;
    },
    subscribed(){
        return this.$store.getters['subscriptions/isSubscribed']
    },
  },
  mounted(){
    if(this.subscribed && !this.$store.getters['user/postPurchaseSurvey']){
      this.showPostPurchaseSurvey = true;
    }
    if(this.$route.name === 'AccountSyncAppLicense'){
      window.location.href = 'storyfolder://refresh-license'
    }
    if("deeplink" in this.$route.query){
      this.$store.dispatch('user/loadMe');
      window.location.href = `storyfolder://${this.$route.query.deeplink}`
    }
  }
}
</script>

<style scoped lang="less">
  @import '../less/variables';


    .account-container{
        display: flex;
        overflow: hidden;
        min-height: 700px;
        main {
            background: #e8e4ed;
            padding: 1em 2em;
            color: @black;
            flex-grow: 1;
        }
        aside{
            background: black;
            display: none;
            padding: 1em 2em;
            width: 270px;
            font-size: 90%;
            flex-shrink: 0;
            a{
                font-weight: 500;
                color: @grayLightMedium;
                padding: 1em 0em;
                display: block;
                &.router-link-active, &:hover{
                    color: @white;
                }
                .icon{
                margin-right: 0.5em;
                }
            }
        }
        li, ul{
            font-size: 1.1em;
            margin: 0;
            padding: 0;
            list-style: none;
        }
    }
    @media(min-width: @secondbreakpoint){
        .account-container{
            aside{
                display: block;
            }
        }
    }
</style>
