<template>
    <div>
        <AccountSection v-if="paused" title="Subscription">
            <Button label="Reactivate Subscription" @click="resumeSubscription" class="special uppercase"  icon="refresh"/>
        </AccountSection>
        <AccountSection title="Payment Method">
            <p v-if="showSuccessMessage" class="green">Your payment method has been updated.</p>
            <p v-if="paymentMethod.id" class="primary payment-method">
                <IconSvg name="card" /> {{paymentMethod.brand}} ending in  <span class="tag last4">• • • • {{paymentMethod.last4}}</span>
            </p>
            <p v-else>
                <IconSvg name="card" /> You have not added a payment method.
            </p>
            <Button @click="updatePaymentMethod">
                {{ paymentMethod.id ? 'Update' : 'Add' }} Payment Method
                <ProgressSpinner v-if="loading" />
            </Button>
        </AccountSection>
        <Button
            label="Cancel Subscription"
            :to="{name:'AccountSubscriptionCancel'}"
            class="warning outline" style="float: right"
            v-if="active"
        />
        <Button label="View Receipts" :to="{name:'AccountInvoicesView'}" />
      &nbsp;
        <Button label="Change Plan" @click="openBillingPortal" />
    </div>
</template>
<script lang="ts">
    import Vue from 'vue';
    import AccountSection from '../molecules/AccountSection.vue';
    import AccountSubscriptionMixin from '../mixins/AccountSubscriptionMixin.vue';
    import Button from '../atoms/Button.vue';
    import IconSvg from '../atoms/IconSvg.vue';
    import ProgressSpinner from '../atoms/ProgressSpinner.vue';

    export default Vue.extend({
        components: {
            AccountSection,
            Button,
            IconSvg,
            ProgressSpinner
        },
        mixins: [AccountSubscriptionMixin],
        data(){
            return {
                loading: false,
                showSuccessMessage: false
            }
        },
        watch: {
            '$route.query': {
                immediate: true,
                handler(){
                    if('session_id' in this.$route.query){
                        // The payment method has been created in Stripe
                        // We must now update it in our system
                        this.loading = true;
                        this.$store
                            .dispatch('paymentMethods/create', this.$route.query.session_id)
                            .then(() => {
                                this.loading = false;
                                this.showSuccessMessage = true;
                                this.$router.replace({query:{}});
                            })
                            .catch(e => {
                                console.log('Could not update payment method for some reason', e)
                            });
                    }
                }
            }
        },
        created(){
            this.$store.dispatch('subscriptions/load')
            this.$store.dispatch('paymentMethods/load');
        },
        methods: {
            updatePaymentMethod(){
                this.loading = true;
                this.$store.dispatch('paymentMethods/getCheckoutUrl').then(url => {
                    window.location.href = url;
                }).catch(e => {
                    console.log(e);
                    this.loading = false;
                });
            },
          openBillingPortal(){
              window.open(`/api/users/billing-portal`, '_blank');
          }
        }
    });
</script>
<style scoped lang="less">
    @import '../../less/variables';
    .icon {
        margin-right: 0.25em;
        font-size: 1.25em;
    }
    .payment-method{
        text-transform: capitalize;
    }
    .last4{
        margin-left: 0.25em;
    }
</style>
