<template>
  <main>
    <div class="modal-container" v-if="showModal" @click="showModal=false">
      <div class="modal narrow-width">
        <div class="logo-container">
          <img src="/img/storyfolder-logo.svg">
        </div>
        <h1>You almost have StoryFolder Desktop.</h1>
        <h3>Lock in your <strong>50% off forever</strong> discount by buying now.</h3>
        <Button label="Lock in 50% Off - Forever" @click="checkout"/>
      </div>
    </div>
    <section class="narrow-width purchase-form">
      <SegmentedControl
        :value="selectedTerm"
        @change="selectedTerm=$event"
        :options="terms">
        <template slot-scope="data">
          {{ data.option.name }}
          <span v-if="data.option.incentive">{{data.option.incentive}}</span>
        </template>
      </SegmentedControl>
      <div v-if="selectedTerm" class="purchase-option">
        <div class="title">
          <div class="logo-container">
            <img src="/img/storyfolder-logo.svg"/>
          </div>
          StoryFolder Beta
        </div>
        <p>
          Import any video to gain insights on structure, engagement, storytelling, visuals, sound and more. Take notes, add tags and export in tons of formats.
        </p>
        <p class="dark">
          <B>Includes:</B>
        </p>
        <ul class="features">
          <li>Instant app download for <IconSvg name="windows" v-if="false" />Windows (.exe) and <IconSvg name="apple" v-if="false" /> macOS (.dmg)</li>
          <li>Unlimited updates</li>
          <li>50%-off forever discount</li>
          <li>30-day money back guarantee</li>
        </ul>
        <div class="checkout-form">
          <div class="pricing">
            <div class="label">Regular price:</div>
            <div class="price strikethrough">{{selectedTerm.regularPrice}}</div>
            <div class="label">Discount: <b>50% off forever</b></div>
            <div class="price">-{{selectedTerm.discountedPrice}}</div>
            <div class="label"><b>Your total / {{selectedTerm.interval}}</b></div>
            <div class="price large">{{selectedTerm.discountedPrice}}</div>
          </div>
          <div class="button-container">
            <Button class="special uppercase" label="Start Subscription" @click="checkout"/>
          </div>
          <p class="guarantee">
            30-day money back guarantee.<br />
            Cancel any time.
          </p>
        </div>
      </div>
    </section>
  </main>
</template>

<script lang="ts">
import SegmentedControl from "@/components/molecules/SegmentedControl.vue";
import IconSvg from "@/components/atoms/IconSvg.vue";
import Button from "@/components/atoms/Button.vue";

export default {
  components: {
    SegmentedControl,
    IconSvg,
    Button
  },
  data(){
    return {
      loading: null,
      showModal: false,
      terms: [
        {
          name: 'Monthly Billing',
          value: 'monthly',
          interval: 'month',
          regularPrice: '$19.90',
          discountedPrice:'$9.95'
        },
        {
          name: 'Annual Billing',
          incentive: '(two months free)',
          value: 'annually',
          interval: 'year',
          regularPrice: '$199.00',
          discountedPrice:'$99.50'
        }
      ],
      selectedTerm: null
    }
  },
  mounted(){
    this.selectedTerm = this.terms[0];
    this.onMouseMove = this.onMouseMove.bind(this);
    window.addEventListener('mousemove', this.onMouseMove)

    window.fbq('trackCustom', 'BeginCheckout');
    dataLayer.push({
      event: 'begin_checkout',
      event_category: 'ecommerce'
    });
  },
  methods: {
    async checkout(){
        window.fbq('track', 'AddToCart', {
          content_name: 'StoryFolder Desktop',
          value: this.selectedTerm.discountedPrice,
          currency: 'USD'
        });
        dataLayer.push({
          event: 'add_to_cart',
          event_category: 'ecommerce'
        });
        // This will redirect to the checkout.
        this.$store.dispatch('subscriptions/checkout', this.selectedTerm.interval)
    },
    onMouseMove(e){
      if(!this.showModal && e.y + e.x < 200){
        this.showModal = true;
        window.removeEventListener('mousemove', this.onMouseMove)
      }
    }
  }
}
</script>

<style scoped lang="less">
  @import '../../less/variables';
  .app-header {
    background: #141627;
  }
  main{
    color: @grayDark;
  }
  section {
    text-align: center;
    &.hero {
      margin-top: 2em;
    }
  }
  .narrow-width{
    margin-left:auto;
    margin-right:auto;
  }
  .purchase-form{
    margin-bottom: 175px;
  }
  .segmented-control{
    margin: 2em 0;
    span{
      display: none;
    }
  }
  p{
    color: @grayMedium;
    &.dark{
      color: @grayDark;
    }
    &.guarantee{
      text-align: center;
      margin-bottom:0;
    }
  }
  ul.features{
    margin: 0;
    padding: 0 0 0 1.25em;
    li {
      margin: 0.25rem 0;
      padding: 0;
    }
  }
  .purchase-option {
    background: #fff;
    border-radius: 0.5rem;
    border:1px solid @grayLightMedium;
    padding: 1em 1.5em 7em 1.5em;
    text-align: left;
    box-shadow: 0 0 10px #fff;
  }
  .title{
    color: @black;
    font-weight: 600;
    font-size: 1.25rem;
  }
  .logo-container{
    background: black;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    margin-right: 0.3em;
    img{
      width: 60%;
    }
  }
  .checkout-form {
    position: fixed;
    left: 0;
    right: 0;
    background: @white;
    bottom: 0;
    width: 100%;
    border-top: 1px solid @grayLightMedium;
    padding: 1em;
    box-sizing: border-box;
    box-shadow: 0  0 1em rgba(0,0,0,0.3);
  }
  .pricing{
    display: grid;
    grid-template-columns: 1fr 90px;
    align-items: center;
    .price{
      text-align: right;
      font-weight: 500;
      color: @grayMedium;
      &.strikethrough{
        text-decoration: line-through;
      }
      &.large{
        font-size: 2rem;
        color: @grayDark;
      }
    }
  }
  .windows /deep/ svg, .apple /deep/ svg{
    zoom: 0.5;
  }
  .button-container{
    text-align: right;
  }
  button{
    width: 100%;
    margin: 0.5em 0 0 0;
    font-size: 1.25em;
  }
  h3{
    color: @grayMedium;
  }

  .modal-container{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: fade(gray, 70%);
    display: flex;
    overflow: scroll;
    align-items: center;
    justify-content: center;
  }
  .modal{
    background: white;
    text-align: center;
    padding: 4em 2em 1em 2em;
    position: relative;
    .logo-container{
      height: 5em;
      width: 5em;
      position: absolute;
      top: 0;
      left: 50%;
      margin-top: -2em;
      margin-left: -2.5em;
    }
    h1{
      margin-bottom: 0.5em;
    }
    h3{

    }
    button {
      margin-top: 0;
    }
  }

  @media(max-width: @zeroethbreakpoint){
    .purchase-form{
      padding:0;
    }
    .purchase-option{
      border-radius: 0;
    }
    .modal{
      border:1em solid transparent;
    }
  }

  @media(min-width: @firstbreakpoint){
    .purchase-option {
      padding: 2em 3em;
    }
    .segmented-control span{
      display: inline;
    }
    .purchase-form{
      margin-bottom: 5rem;
    }
    .checkout-form{
      position: static;
      padding: 0;
      box-shadow: none;
      margin-top: 2em;
      padding-top: 1em;
    }
    .modal{
      border-radius: 0.5rem;
      padding: 4em 3em 3em 3em;
    }
    button{
      width: auto;
    }
  }

  @media(min-width: @secondbreakpoint){
  }

</style>
